import React, { useState, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

const DropFile = ({ onFileUpload, onComplete, closeModal, activeNavItem, textInput, onError, editAction, editFileId, isImported, originalFileId}) => {

    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [showDropZone, setShowDropZone] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    const handleDivClick = () => {
      setShowDropZone(true); // Set state to show drop zone when the specific div is clicked
    };

    const handleFileDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        setSelectedFile(file);
    
        if (activeNavItem !== 'Presenters') {
          // Automatically upload the file if activeNavItem is not 'Presenters'
          try {
            if(editAction === 'imageUpload' ) {
              handleImageAutoSubmit(acceptedFiles)
            } else  { 
              handleFileAutoSubmit(acceptedFiles)
            }
            console.log('File uploaded successfully!');
          } catch (error) {
            console.error('Error occurred while uploading the file:', error);
            // Handle error
          }
        }
      };
    
      const handleSubmit = async () => {
        if (!textInput || !textInput.trim()) {
          onError('Please Enter Presenter Name');
        } else {
        if (selectedFile) {
          try {
            const formData = new FormData();
            let userId=localStorage.getItem("userId")
            //formdata.append("file", files[0].file);
            formData.append("userId", userId);
            console.log("textInput",textInput)
            formData.append("presenterName", textInput);
            formData.append('file', selectedFile);
                  let  headers={
                "Content-Type": "multipart/form-data",
              }
            
              onFileUpload();
              const response = await callAPI(apiUrls.SAMPLEUPLOAD, {}, "POST", formData,{},headers);
              console.log(response)
              if (response.status == 200) {
        
                onComplete(response.data.id, textInput); 
                console.log('File uploaded successfully!');
                 // Update state to indicate successful file upload
               // onFileUpload(); // Notify parent component about successful upload
                // Handle success
              //  onUploadComplete();
              } else {
                console.error('File upload failed.');
                // Handle failure
              }
              
          } catch (error) {
            console.error('Error occurred while uploading the file:', error);
            // Handle error
          }
        } else {
          onError('Please Upload Audio Sample File');
        }
        }
      };

    const handleFileAutoSubmit = async (acceptedFiles) => {
      const allowedFileTypes = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
      if (acceptedFiles.length > 0 && allowedFileTypes.includes(acceptedFiles[0].type)) {
      const formData = new FormData();
      let userId=localStorage.getItem("userId")
      //formdata.append("file", files[0].file);
      formData.append("userId", userId);
      if(isImported === true ) {
        formData.append("isImported", 1);
        formData.append("original_fileId", originalFileId);

      }
      formData.append('file', acceptedFiles[0]);
            let  headers={
          "Content-Type": "multipart/form-data",
        }
      
  
      try {
        onFileUpload();
        const response = await callAPI(apiUrls.CONVERT, {}, "POST", formData,{},headers);
        console.log(response)
        if (response.status == 200) {
          onComplete(response.data.id,response.data.title); 
          console.log('File uploaded successfully!');
           // Update state to indicate successful file upload
         // onFileUpload(); // Notify parent component about successful upload
          // Handle success
        //  onUploadComplete();
        } else {
          console.error('File upload failed.');
          // Handle failure
        }
      } catch (error) {
        console.error('Error occurred while uploading the file:', error);
        // Handle error
      }
    }else {
      console.error('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      onError('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      // Handle invalid file type
    }
    };
  
    const handleImageAutoSubmit = async (acceptedFiles) => {
    const allowedMediaTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/x-ms-wmv'/* Add more image MIME types as needed */];

// Array for video MIME types
//const allowedVideoTypes = [, /* Add more video MIME types as needed */];

      if (acceptedFiles.length > 0 && allowedMediaTypes.includes(acceptedFiles[0].type)) {
      const formData = new FormData();
      let userId=localStorage.getItem("userId")
      //formdata.append("file", files[0].file);
      formData.append("userId", userId);
      formData.append("slideId", editFileId);
      
  
      formData.append('file', acceptedFiles[0]);
            let  headers={
          "Content-Type": "multipart/form-data",
        }
      
  
      try {
        onFileUpload();
        const response = await callAPI(apiUrls.MEDIAUPLOAD, {}, "POST", formData,{},headers);
        console.log(response)
        if (response.status == 200) {
          onComplete(response.data.id,response.data.title); 
          console.log('File uploaded successfully!');
           // Update state to indicate successful file upload
         // onFileUpload(); // Notify parent component about successful upload
          // Handle success
        //  onUploadComplete();
        } else {
          console.error('File upload failed.');
          // Handle failure
        }
      } catch (error) {
        console.error('Error occurred while uploading the file:', error);
        // Handle error
      }
    }else {
      console.error('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      onError('Invalid file type. Please upload a .ppt, .pptx, or .pdf file.');
      // Handle invalid file type
    }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleFileDrop });
    const label1 = editAction === "imageUpload"?"Images or Videos files supported. Max size: 20 MB":"PPT or PDF files supported. Max size: 15 MB"
  
  return (
<div>
          
        {  activeNavItem === 'Presenters' ? (
          <div>
          <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
            <div class="uploadFile" onClick={handleDivClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                 <path d="M16 3V6.33333M16 24V29.3333M7.66667 16H3M28.3333 16H26.3333M24.6095 24.6095L23.6667 23.6667M24.8856 7.22105L23 9.10667M6.5621 25.4379L10.3333 21.6667M6.83824 6.94491L9.66667 9.77333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M16 12L12 8M12 8L8 12M12 8V16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#525252" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
               </svg>
               <p class="hoverTooltip">Upload your 30s / 1m voice sample </p>
               <span>MP3, M4A, AAC or WAV files supported. Max size: 15 MB</span>
            </div>
            </div> 
            {selectedFile ? (
                    <div class="voiceTxt mt-32 mb-32">
                      <div class="vIcon mr-8">
                         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                           <path d="M9 14V4.69166C9 4.04875 9 3.72729 9.13541 3.53369C9.25365 3.36464 9.4363 3.25175 9.64038 3.2216C9.8741 3.18707 10.1616 3.33083 10.7367 3.61835L13.5 5.00002M9 14C9 15.2427 7.99264 16.25 6.75 16.25C5.50736 16.25 4.5 15.2427 4.5 14C4.5 12.7574 5.50736 11.75 6.75 11.75C7.99264 11.75 9 12.7574 9 14Z" stroke="#424242" stroke-linecap="round" stroke-linejoin="round"/>
                         </svg>
                      </div>
                      <div> {selectedFile.name}</div>
                      <a href="javascript:void(0);" class="ml-12">Remove</a>
                   </div>
            ) : (
              <p></p>
            )}
              <div class="dwnText">
               Don’t know what to speak? <a href="javascript:void(0);">Download a sample script</a>
            </div>
            
            <div class="flex mt-12 actionButton">
                  
                  <button class="btn InversePrimary" onClick={handleSubmit}>Generate Voice Sample</button>
               </div>
           </div> 

        ) : (
          <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
            <div class="uploadFile" onClick={handleDivClick}>
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16 12L12 8M12 8L8 12M12 8V16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#525252" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
             </svg>
             <p class="hoverTooltip">Drag and drop your file here </p>
             <span>{label1}</span>
             </div>
             </div>

        )}      

          
          </div>
  );
};

export default DropFile;
