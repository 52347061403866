import React, { useState, useEffect } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import './StripeButton.css'; 
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

// Initialize Stripe
const stripePromise = loadStripe('pk_test_51P82osHjl5nedYUgzDXb1n783GwQswARj2R7fRNe5lvhIc8Q5kTFgbqY3TCUput7Lja4agAeRPxEzd05y7VTdQu200WOLlj0mf'); // Replace with your actual Stripe publishable key

const CheckoutForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: 'white',
        backgroundColor: 'black',
        '::placeholder': {
          color: 'white',
        },
        border: '1px solid white',
        width:'500px'
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log('Payment succeeded:', paymentIntent);
      // Handle post-payment success (e.g., update user plan)
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <CardElement  options={CARD_ELEMENT_OPTIONS} />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const PaymentSetting = ({ }) => {

    const [clientSecret, setClientSecret] = useState('');
    
    const handleUpgradeClick = async () => {
      const amount = 50; // Example amount in cents ($10)
      console.log("jjjjj")
      try {
        const formData = new FormData();
         formData.append("amount", amount);


         const headers={
          "Content-Type": "application/json",
          }
         
        const response = await callAPI(apiUrls.CREATEPAYMETINTENT, {}, 'POST', formData, {}, headers);
        console.log("response.clientSecret", response.data.clientSecret)
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error('Error creating payment intent:', error);
      }
    };
    
 
    return (
        <div class="dashContent">
          <h1>Payment Settings</h1>
          <div class="dForm mt-32">
            <div class="wrapper">
           
                <section class="infBox viewPlan">
                  <h3>Your Plan
                     <span>Review your current plan</span>
                  </h3>
                  <script async src="https://js.stripe.com/v3/buy-button.js"></script>
                   <div class="dbBox mt-12">
                     <div class="row jcSpacebetween gap24">
                         <div class="col">
                            <label>Current Plan</label>
                         </div>
                         <div class="col mrAuto">
                           <span>Free Plan</span>
                           <label>1 Deck</label>
                           <label>3000 Words AI Summarization</label>
                           <label>30 mins Audio</label>
                           <label>1 Voice Clone</label>
                           <label>Unlimited Share</label>
                           <label>Price: Free for first month then 5.99+Tax every months  </label>
                           





                         </div>
                         <div class="col">
                         {/* <a href="javascript:void(0);" className="btn PrimaryOutline"  onClick={handleUpgradeClick} >Upgrade Plan</a> */}
                         <button className="btn PrimaryOutline" onClick={handleUpgradeClick}>
                    Upgrade Plan
                  </button>
                         </div>
                      </div>
                      <div class="row" style={{width:"100%"}}>
                   {clientSecret && (
            <Elements stripe={stripePromise}>
              <CheckoutForm clientSecret={clientSecret} />
            </Elements>
          )} </div>
                   </div>
         
                   
                </section>
        
             </div>
          </div>
      </div>

    );
};

export default PaymentSetting;