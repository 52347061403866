import React, { useRef, useEffect, useState } from 'react';
import DraggableVideo from '../components/DraggableVideo';
import EditSlide from '../components/EditSlide';


const DeckContentItemDetail = ({ selectedItemIndex, indexNo, onSaveContent, openModal, title, itemId }) => {
  const imageRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [prePreviewWindowPosition, setPrePreviewWindowPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });
  const EMU_TO_PIXELS_CONVERSION_FACTOR = 0.02645833;
  let newVideoSizeInPixels = {};
  const [videoSizeInPixels, setVideoSizeInPixels] = useState({});
  const [videosData, setVideosData] = useState([]);
  

  useEffect(() => {
    console.log("Log0",selectedItemIndex)
    
    if (selectedItemIndex && selectedItemIndex.mediaDetail && Array.isArray(selectedItemIndex.mediaDetail) && selectedItemIndex.mediaDetail.length > 0) {
      console.log("Log1",selectedItemIndex.mediaDetail)
      const newVideosData = selectedItemIndex.mediaDetail.map(detail => {
        console.log("Logloop",detail )
        if (detail.url !== '') {
          console.log("Useeeeee Inside If", detail);

          let newVideourl;
          if (detail.source === 1) {
        //    const videoIdMatch = detail.url.match(/[?&]v=([^&]+)/);
        //    const videoId = videoIdMatch ? videoIdMatch[1] : null;
        const videoIdMatch = detail.url.match(/[?&]v=([^&]+)/) || detail.url.match(/(?:youtu\.be\/|\/embed\/|\/v\/|\/\?v=|&v=)([^&?]+)/);
        const videoId = videoIdMatch ? videoIdMatch[1] : null;
          if (videoId) {
              newVideourl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
            } else {
              console.error("Unable to extract video ID from the URL");
              return null;
            }
          } else {
            newVideourl = detail.url;
          }

          if (detail.transform && detail.size) {
            const vidTransformData = JSON.parse(detail.transform);
            const vidSizeData = JSON.parse(detail.size);
            console.log(vidTransformData);
            console.log(vidSizeData);
            const widthInPixels = 300;
            const heightInPixels = Math.round((widthInPixels * vidSizeData.height.magnitude) / (vidSizeData.width.magnitude * vidTransformData.scaleX / vidTransformData.scaleY));
            newVideoSizeInPixels = {
              width: widthInPixels,
              height: heightInPixels,
              posX: "0",
              posY: "0",
              percentX: "0%",
              percentY: "0%",
              percentWidth: "30%",
              percentHeight: "30%",
            };
            console.log("newVideoSizeInPixelsTrans", newVideoSizeInPixels);
          } else {
            const videoSizeInEMU = {
              width: { magnitude: 12000, unit: "EMU" },
              height: { magnitude: 9000, unit: "EMU" },
            };
            newVideoSizeInPixels = {
              width: Math.round(videoSizeInEMU.width.magnitude * EMU_TO_PIXELS_CONVERSION_FACTOR),
              height: Math.round(videoSizeInEMU.height.magnitude * EMU_TO_PIXELS_CONVERSION_FACTOR),
              posX: "0",
              posY: "0",
              percentX: "0%",
              percentY: "0%",
              percentWidth: "30%",
              percentHeight: "30%",
            };
          }

          if (detail.coordinateLocation) {
            console.log("ddddddddddd", detail.coordinateLocation);
            let locationSizeData = JSON.parse(detail.coordinateLocation);
            newVideoSizeInPixels.posX = locationSizeData.position.x ? locationSizeData.position.x : "0";
            newVideoSizeInPixels.posY = locationSizeData.position.y ? locationSizeData.position.y : "0";
            newVideoSizeInPixels.width = locationSizeData.size.width ? parseInt(locationSizeData.size.width, 10) : newVideoSizeInPixels.width;
            newVideoSizeInPixels.height = locationSizeData.size.height ? parseInt(locationSizeData.size.height, 10) : newVideoSizeInPixels.height;
            newVideoSizeInPixels.percentX = locationSizeData.position.percentX ? `${locationSizeData.position.percentX}%` : "0%";
            newVideoSizeInPixels.percentY = locationSizeData.position.percentY ? `${locationSizeData.position.percentY}%` : "0%";
            newVideoSizeInPixels.percentWidth = locationSizeData.size.percentWidth ? `${locationSizeData.size.percentWidth}%` : "30%";
            newVideoSizeInPixels.percentHeight = locationSizeData.size.percentHeight ? `${locationSizeData.size.percentHeight}%` : "30%";
          }

          setVideoSizeInPixels(newVideoSizeInPixels);

          return {
            videoUrl: newVideourl,
            mediaid: detail.mediaId,
            thumbnail: detail.thumbnail,
            mediaSource: detail.source,
            videoSizeInPixels: newVideoSizeInPixels,
          };
        }

        return null;
      }).filter(Boolean);
console.log("Log newVideosData", newVideosData)
      setVideosData(newVideosData);
    } else {
      setVideosData([])
    }
  }, [selectedItemIndex]);

  const handleImageLoad = () => {
    const { naturalWidth, naturalHeight } = imageRef.current;
    setImageDimensions({ width: naturalWidth, height: naturalHeight });

    const prePreviewWindowRect = document.querySelector('.subprePreviewWindow').getBoundingClientRect();
    console.log("prePreviewWindowRect", prePreviewWindowRect);
    setPrePreviewWindowPosition({ x: prePreviewWindowRect.left, y: prePreviewWindowRect.top, w: prePreviewWindowRect.width, h: prePreviewWindowRect.height });
  };

  const handleLocContent = (updatedContent) => {
    console.log("Inside handleLocContent", indexNo, updatedContent);
    onSaveContent(indexNo, updatedContent);
  };
  console.log(" Log videosDataffff", videosData)
  //console.log("videoSizeInPixelsffff", videoSizeInPixels)
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const handleSelectMedia = (index) => {
    setSelectedMediaIndex(index);
  };
  
  return (
    <div className="dashContent">
      {selectedItemIndex && (
      <EditSlide  openModal={openModal} slideId={selectedItemIndex.id} indexNo={indexNo} title={title} itemId={itemId} />
      )}
      <div className="prePreviewWindow">
        <div className="subprePreviewWindow" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {selectedItemIndex && (
            <img src={selectedItemIndex.value} alt={selectedItemIndex.title} ref={imageRef} onLoad={handleImageLoad} />
          )}
          {console.log("videosData log",videosData)}
          {videosData.map((videoData, index) => (
            <DraggableVideo
              key={index}
              medKey={index}
              videoUrl={videoData.videoUrl}
              thumbnail={videoData.thumbnail}
              prePreviewWindowPosition={prePreviewWindowPosition}
              videoSizeInPixels={videoData.videoSizeInPixels}
              mediaid={videoData.mediaid}
              mediaSource={videoData.mediaSource}
              selectedItemIndex={selectedItemIndex}
              handleLocContent={handleLocContent}
              selectedMediaIndex={selectedMediaIndex}
              handleSelectMedia={handleSelectMedia}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeckContentItemDetail;
