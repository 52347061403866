import React, { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import { Rnd } from "react-rnd";
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

//import 'react-resizable/css/styles.css';

const DraggableVideo = ({ medKey, videoUrl, thumbnail, prePreviewWindowPosition, videoSizeInPixels, mediaid, mediaSource , selectedItemIndex, handleLocContent, selectedMediaIndex, handleSelectMedia }) => {
  const isSelectedMedia = selectedMediaIndex === mediaid;
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: isSelectedMedia ? "solid 2px white" : "",
  //  background: "#808080",
    // opacity: "0.5"   
  };
  console.log("videoUrldddddddddddddddddddddd",videoUrl);
 
 //const [position, setPosition] = useState({ x: 0, y:0});
 const [position, setPosition] = useState({ x: videoSizeInPixels.posX, y:videoSizeInPixels.posY});
  const [size, setSize] = useState({ width: videoSizeInPixels.width, height: videoSizeInPixels.height });

  //const [position, setPosition] = useState({ x: videoSizeInPixels.percentX, y:videoSizeInPixels.percentY});
  //const [size, setSize] = useState({ width: videoSizeInPixels.percentWidth, height: videoSizeInPixels.percentWidth });

 //const [size, setSize] = useState({ width: 300, height: 300 });
  const bounds = 'parent';
  //const [controlsEnabled, setControlsEnabled] = useState(true);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

/////////////////Handle disselect media on clicking somewhere else--START/////////////////////

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (!event.target.closest('.react-draggable')) {
        handleSelectMedia(null);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleSelectMedia]);

  const handleMouseDown = (event) => {
    if (!event.currentTarget.contains(event.target)) {
      handleSelectMedia(null); // Clicked outside the component, deselect
    }
  };

/////////////////Handle disselect media on clicking somewhere else-- END/////////////////////


  const onDeleteSelectedItem = () => {
    // Call onDeleteSelectedItem with medKey
    console.log('Delete selected item:', mediaid, medKey);
    
    const formData = new FormData();
    formData.append("mediaId", mediaid);
    const headers={
      "Content-Type": "application/json",
      }
    const response =  callAPI(apiUrls.DELETEMEDIA, {}, "POST",formData,{},headers);
    
    const updatedMediaDetail = selectedItemIndex.mediaDetail.filter((item, index) => index !== medKey);
    console.log(updatedMediaDetail)
    const updatedContent = {
      ...selectedItemIndex,
      mediaDetail: updatedMediaDetail,
    };
    handleLocContent(updatedContent); 
  };

  useEffect(() => {
    const handleDeleteKeyPress = (event) => {
      if ((event.key === 'Delete' || event.key === 'Backspace')  && selectedMediaIndex === mediaid) {
        onDeleteSelectedItem();
      }
    };

    document.addEventListener('keydown', handleDeleteKeyPress);

    return () => {
      document.removeEventListener('keydown', handleDeleteKeyPress);
    };
  }, [selectedMediaIndex, mediaid]);

  const handleMediaClick = () => {
    handleSelectMedia(mediaid);
  };


 

  useEffect(() => {
    console.log("Use effect")
    // Update the size state when videoSizeInPixels changes
    setSize({
      width: videoSizeInPixels.width,
      height: videoSizeInPixels.height,
    });
  //  setPosition({ x: 0, y: 0 });
  setPosition({ x: videoSizeInPixels.posX, y: videoSizeInPixels.posY});
  }, [videoSizeInPixels]);

  const playPauseHandler = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const videoStyle = {
 //   width:"317px"
    width:"100%",
  //  height:"100%"
 //   width: videoSizeInPixels.width}px`, 
  //  height: `${videoSizeInPixels.height}px` 
  };



  // const toggleControls = () => {
  //   setControlsEnabled(!controlsEnabled);
  // };

  const handleDragStop = async (e, d) => {
    setPosition({ x: d.x, y: d.y });
    console.log('Position after drag:', { x: d.x, y: d.y });
    console.log('Size after drag:', { width: size.width, height: size.height });
   
    // Call your API here with the position and size
    await sendToBackend({ position: { x: d.x, y: d.y }, size });
  };

  const handleResizeStop = async (e, direction, ref, delta, position) => {
    setSize({
      width: ref.style.width,
      height: ref.style.height,
    });
    setPosition({ ...position });
    console.log('Size after resize:', { width: ref.style.width, height: ref.style.height });
    console.log('Position after resize:', { x: position.x, y: position.y });

    // Call your API here with the position and size
      await sendToBackend({ position, size: { width: ref.style.width, height: ref.style.height } });
  };
  
  const sendToBackend = async (data) => {

    console.log("prePreviewWindowPositionD",prePreviewWindowPosition)
    console.log("dataaaa",data.position.x,data.position.y,data.size.width,data.size.width)
    const percentWidth = ((parseFloat(data.size.width) / prePreviewWindowPosition.w) * 100).toFixed(2);
    const percentHeight = ((parseFloat(data.size.height) / prePreviewWindowPosition.h) * 100).toFixed(2);

    const percentX = ((data.position.x / prePreviewWindowPosition.w) * 100).toFixed(2);
    const percentY = ((data.position.y / prePreviewWindowPosition.h) * 100).toFixed(2);

    console.log('Percentage Size:', { percentWidth, percentHeight });
    console.log('Percentage Position:', { percentX, percentY });

    data.mediaid = mediaid;
    console.log("mediaId",mediaid)
    data.position.percentX = percentX
    data.position.percentY = percentY
    data.size.percentWidth = percentWidth
    data.size.percentHeight = percentHeight

    console.log("updatedCLocontentBefore",selectedItemIndex)

    // const updatedContent = {
    //   ...selectedItemIndex,
    //   mediaDetail: {
    //     ...selectedItemIndex.mediaDetail,
    //     coordinateLocation: JSON.stringify(data),
    //   },
    // };
    const updatedMediaDetail = selectedItemIndex.mediaDetail.map((item, index) => {
      console.log("key,index", medKey,index)
      if (index === medKey) {
        // Update coordinateLocation for the first item in the array
        return {
          ...item,
          coordinateLocation: JSON.stringify(data),
        };
      }
      return item; // Return unchanged for other items
    });
    
    const updatedContent = {
      ...selectedItemIndex,
      mediaDetail: updatedMediaDetail, // Update mediaDetail with the modified array
    };

    handleLocContent(updatedContent); 
  // Modify this based on your actual response
    console.log("updatedCLocontent",updatedContent)
    console.log("sendToBackend",data)
    try {
      const response = await callAPI(apiUrls.UPDATELOCATION, {}, "POST",data,{});

    } catch (error) {
      console.error('Error sending data to backend:', error);
    }
  };
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const toggleVideo = () => {
    setIsVideoPlaying(!isVideoPlaying);
    setShowButton(false);
  };
  const handleOutsideClick = (event) => {
    if (videoRef.current && !videoRef.current.contains(event.target)) {
      setIsVideoPlaying(false);
      setShowButton(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, []);

    return (
      <Rnd
      style={style}
      size={size}
      position={position}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
      bounds={bounds}
      lockAspectRatio={true}
      onClick={handleMediaClick}
      
    >
  <>
  {mediaSource === 2 && (
    <>
      <div className="draggableVideo">
      {isVideoPlaying ? (
        <video
          ref={videoRef}
          src={videoUrl}
          style={videoStyle}
          controls={true}
          autoPlay
          type="video/mp4"
        />
        ) : (
          <img src={thumbnail} style={{width:"100%"}} draggable="false" />
        )}
      </div>
      {showButton && <button
        className="btn InversePrimary"
        onClick={toggleVideo}
        style={{ position: "absolute", textAlign: "center" }}
      >
        {isPlaying ? "Pause" : "Play"}
      </button>}
    </>
  )}
  {mediaSource === 1 && (
    
     <>
     <div className="draggableVideo" style={{width:"100%", height:"100%", textAlign: "center"}}>
          {isVideoPlaying ? (
            <iframe
            ref={videoRef}
            width="100% %"
            height="100%"
            src={videoUrl}
            frameborder="0"
            autoPlay
          ></iframe>
          ) : (
            <img src={thumbnail} style={{width:"100%"}} draggable="false" />
          )}
          </div>
          {showButton && <button
        className="btn InversePrimary"
        onClick={toggleVideo}
        style={{ position: "absolute", textAlign: "center" }}
      >
        {isPlaying ? "Pause" : "Play"}
      </button>}
        </>
  
    
  )}
    {mediaSource === 4 && (
     <>
     <div className="draggableVideo" style={{width:"100%", height:"100%", textAlign: "center"}}>
          {isVideoPlaying ? (
            <iframe
            ref={videoRef}
            width="100% %"
            height="100%"
            src={videoUrl+"?autoplay=1"}
            frameborder="0"
            autoPlay
          ></iframe>
          ) : (
            <img src={thumbnail} style={{width:"100%"}} draggable="false" />
          )}
          </div>
          {showButton && <button
        className="btn InversePrimary"
        onClick={toggleVideo}
        style={{ position: "absolute", textAlign: "center" }}
      >
        {isPlaying ? "Pause" : "Play"}
      </button>}
        </>
  )}
    {mediaSource === 3 && (
   
         <img
          src={videoUrl}
          style={videoStyle}
          draggable="false" 
        />
   
  )}
</>
    
    </Rnd>
  );
};

export default DraggableVideo;
