import React, { useState, useEffect, useRef } from 'react';
import AudioPlayer from '../components/AudioPlayer.js'; 
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import {defaultConfig} from '../config';
import StreamPlayer from '../components/StreamPlayer.js'; 

const DeckContentNavigationRight = ({ indexNo, selectedItemIndex, onSaveContent, cloneVoices, setCheckEditable, openModal }) => {

      const audioRef = useRef(null);
      const [audioUrl, setAudioUrl] = useState('');
      const [isSummarized, setIsSummarized] = useState(0);
      const [audioPresenterName, setAudioPresenterName] = useState('');
      const [isSumLoading, setIsSumLoading] = useState(false);
      const [customPrompt, setCustomPrompt] = useState(false);
      
      let shouldSetAutoPlay = useRef(true); 
      
   useEffect(() => {
      console.log("selectedItemIndex", selectedItemIndex, indexNo)
      if (selectedItemIndex ) {
         setErrorMessage('');
        // setSelectedOption(cloneVoices.length > 0 ? cloneVoices[0].presenterId : '');
         console.log("pppppp", selectedItemIndex.text);
         setUpdatedText(selectedItemIndex.text );
         setAudioUrl(selectedItemIndex.audioUrl);
         setIsSummarized(selectedItemIndex.isSummarized);
         const foundVoice = cloneVoices.find(voice => voice.presenterId === selectedItemIndex.presenterId);
         const audioPresenterName = foundVoice ? foundVoice.presenterName : '';
         if(audioPresenterName) {
            setAudioPresenterName(audioPresenterName);
         }
         console.log("isAudioGenerated",selectedItemIndex.isAudioGenerated);
         console.log("audioUrl",selectedItemIndex.audioUrl);

         if(selectedItemIndex.isAudioGenerated==0){
            setShowPlayButton(true);
         } else {
         //    console.log("shouldSetAutoPlay", shouldSetAutoPlay)
         //    if (!shouldSetAutoPlay.current) {   console.log("shouldSetAutoPlayiffff")
         //       setAutoPlay(false); // Set autoplay to false if audio is already generated
         //   } else {console.log("shouldSetAutoPlayeeeeeee")
             const uniqueParam = `?timestamp=${Date.now()}`; 
            setAudioUrl(selectedItemIndex.audioUrl+uniqueParam);
            setShowPlayButton(false);
        //   }
         
         }
         
      }

   }, [selectedItemIndex]);
  
  const [isEditable, setIsEditable] = useState(false);
  const [updatedText, setUpdatedText] = useState(selectedItemIndex?.text || '');
  const [editMode, setEditMode] = useState(false);

  const handleEditClick = () => {
   console.log('editenabled')
   setIsEditable(true);
   setEditMode(true);
   setCheckEditable(true)
 };

 console.log("cloneVoices",cloneVoices);

 const handleCrossClick = () => {
   setEditMode(false);
   setIsEditable(false);
   setUpdatedText(selectedItemIndex.text );
   setCheckEditable(false)
 };

  const handleSaveClick = async () => {
   // Call your API function to update text in the backend
   try {
      console.log("sssss",selectedItemIndex.textId, updatedText)
      const formData = new FormData();
      //formdata.append("file", files[0].file);
      formData.append("textId", selectedItemIndex.textId);
      formData.append("text", updatedText);
      formData.append("imageId", selectedItemIndex.id);
      
   

      const headers={
        "Content-Type": "application/json",
        }
      const response = await callAPI(apiUrls.CREATESLIDETEXT, {}, "POST",formData,{},headers);
      
      
      if(!selectedItemIndex.textId) {
         selectedItemIndex.textId = response.data.textId
      }
      
      const updatedContent = { ...selectedItemIndex, text: updatedText,isAudioGenerated:0 }; // Modify this based on your actual response
console.log("updatedContent",updatedContent)
console.log("selectedItemIndex.index",indexNo)
setUpdatedText(updatedText);
      // Invoke the function from DeckContentItemLayout to update content and index state
      onSaveContent(indexNo, updatedContent);

    // await updateTextInBackend(selectedItemIndex.id, updatedText); // Pass necessary parameters
     setIsEditable(false); // Disable edit mode after successful update
     setEditMode(false);
     setCheckEditable(false)
   } catch (error) {
     // Handle errors appropriately
     console.error('Error updating text:', error);
   }
 };

 const maxLength = 500;
  const handleTextAreaChange = (event) => {
   setUpdatedText(event.target.value);

   // const { value } = event.target;
   // if (value.length <= maxLength) {
   //   setUpdatedText(value);
   // }

 }

 const charactersLeft = maxLength - updatedText.length;

 const [isLoading, setIsLoading] = useState(false);
 const [showPlayButton, setShowPlayButton] = useState(true);
 const [autoPlay, setAutoPlay] = useState(false);
 
 const fetchAudioUrl = async () => {
   try {
      if(editMode) {
         handleSaveClick ()
      }
  setAudioPresenterName(selectedOption);

      let userId=localStorage.getItem("userId")
      
      let formdata={
        audioText:updatedText,
        userId:userId,
        textId:selectedItemIndex.textId,
        presenterId:selectedPresenter

      }
      const apiResponse = await callAPI(apiUrls.GENAUDIO, {}, "POST", formdata);
      console.log("apiResponse ",apiResponse.data.audioUrl )
      const  audioUrl  = apiResponse.data.audioUrl
      console.log("ausss",audioUrl)
      if(audioUrl) {
 
         console.log("fixme",audioUrl)
         console.log("already fix",apiResponse.data.audioUrl)
        
         setShowPlayButton(false);
         setIsLoading(false);
         const uniqueParam = `?timestamp=${Date.now()}`; 
         setAudioUrl(audioUrl+uniqueParam);
      //   setAudioPresenterName(selectedOption);
     //       shouldSetAutoPlay.current = false; 
           
         const updatedContent = { ...selectedItemIndex, audioUrl: audioUrl, text: updatedText, isAudioGenerated:1 }; // Modify this based on your actual response
         console.log("updatedContent",updatedContent)
         console.log("selectedItemIndex.index",indexNo)
                  // Invoke the function from DeckContentItemLayout to update content and index state
         onSaveContent(indexNo, updatedContent); 
     //    setAutoPlay(true)

      }
   } catch (error) {
     console.error('Error fetching audio URL:', error);
   } finally {
     ///setIsLoading(false);
   }
 };


 const handlePlayClick = () => {
   if (selectedPresenter === null) {
      setErrorMessage('Please select a presenter.'); // Set error message for null selectedPresenter
      return; // Don't proceed with the API call if selectedPresenter is null
    }
      console.log("isLoading before",isLoading)
      setIsLoading(true);
      console.log("isLoading after",isLoading)
     fetchAudioUrl();
 };
 
 const onCompleteAction = (newText) => {
   console.log("insideAp", newText)
   if(newText.trim() !== '') {
      setUpdatedText(newText)
      setCustomPrompt(true);
      generateAISummary(newText, true);
   }
   // Your generateAP logic here
 };


const handleCustomPrompt= async () => {
   openModal(null,'','customprompt',indexNo,'' ,onCompleteAction);
}


 const generateAISummary = async (newText = '', customP = false) => {
   try {
      if (isSummarized === 1 || updatedText.trim() === '') {
         return; 
       }
       if(editMode) {
         handleSaveClick ()
      }
      let userId=localStorage.getItem("userId")
      
      let formdata={
        text:customP?newText:updatedText,
        userId:userId,
        textId:selectedItemIndex.textId,
        customPrompt:customP?1:0
      }
      console.log("formdatadddd",formdata)
         setIsSumLoading(true)
         const apiResponse = await callAPI(apiUrls.CREATEAISUMMARY, {}, "POST", formdata);
         console.log("apiResponse CREATEAISUMMARY",apiResponse.data)
         setIsSumLoading(false)
         if(apiResponse.data.summarizedText) {
         
         const updatedContent = { ...selectedItemIndex, text: apiResponse.data.summarizedText,isAudioGenerated:0,isSummarized:1 }; // Modify this based on your actual response
         console.log("updatedContent",updatedContent)
         setCustomPrompt(false);
         console.log("selectedItemIndex.index",indexNo)
         setUpdatedText(apiResponse.data.summarizedText);
         setIsSummarized(1);
               // Invoke the function from DeckContentItemLayout to update content and index state
          onSaveContent(indexNo, updatedContent);
         }  
      
   } catch (error) {
     console.error('Error fetching audio URL:', error);
   } finally {
     ///setIsLoading(false);
   }
 };
 

 const [errorMessage, setErrorMessage] = useState('');
 const [selectedPresenter, setSelectedPresenter] = useState(null);
 const [selectedOption, setSelectedOption] = useState('voices'); 
 const [dropdownVisible, setDropdownVisible] = useState(false);
 const dropdownRef = useRef(null);
 const handleDropdownToggle = () => {
   setDropdownVisible(!dropdownVisible);
 };

 const handleDropdownItemClick = (presenterId,option) => {
   // Handle the selection from the dropdown here
   setSelectedOption(option);
   setErrorMessage('');
   setSelectedPresenter(presenterId); 
   console.log('Selected option:', option);
   setDropdownVisible(false); // Close the dropdown after selection
 };
 const handleClickOutside = (event) => {
   

   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      
     setDropdownVisible(false);
   }
 };

 useEffect(() => {
   // Close dropdown when clicking outside
   setErrorMessage('');
   document.addEventListener('mousedown', handleClickOutside);

   return () => { 
      
     document.removeEventListener('mousedown', handleClickOutside);
   };
 }, []);

 

  return (
    <div class="rftCol">
    <div class="deckrftSidebar">
       <div class="notesSection">
          <div class="heads">
             <h5>Notes</h5>
             <div class="actionNav">
                <a href="javascript:void(0);" class="nobor" style={{ display: editMode ? 'block' : 'none' }}  onClick={handleCrossClick}>
                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M15 5L5 15M5 5L15 15" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                </a>
                <a href="javascript:void(0);" style={{ display: !editMode ? 'block' : 'none' }}  onClick={handleEditClick}>
                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M14.9999 8.33326L11.6666 4.99993M2.08325 17.9166L4.90356 17.6032C5.24813 17.5649 5.42042 17.5458 5.58146 17.4937C5.72433 17.4474 5.86029 17.3821 5.98566 17.2994C6.12696 17.2062 6.24954 17.0836 6.49469 16.8385L17.4999 5.83326C18.4204 4.91279 18.4204 3.4204 17.4999 2.49993C16.5795 1.57945 15.0871 1.57945 14.1666 2.49992L3.16136 13.5052C2.91621 13.7503 2.79363 13.8729 2.70045 14.0142C2.61778 14.1396 2.55243 14.2755 2.50618 14.4184C2.45405 14.5794 2.43491 14.7517 2.39662 15.0963L2.08325 17.9166Z" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                </a>
                <a href="javascript:void(0);" style={{ display: editMode ? 'block' : 'none' }} onClick={handleSaveClick}>
                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M16.6667 5L7.50004 14.1667L3.33337 10" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                </a>
             </div>
          </div>
          <div class="contentEditor">
          <textarea className="scrollBarC"     
        value={updatedText || ""}
        readOnly={!isEditable}
        onChange={handleTextAreaChange}
        style={{
         pointerEvents: isEditable ? 'auto' : 'none',  // Disable all interactions when not editable
       }}
        />
             <div class="remainingC">
             {/*updatedText.length}/{maxLength*/}
             {updatedText.length}
             </div>
             <div class="infoText">
                {/* <div class="wordsLeft">
                   <span>{charactersLeft}left</span>
                   <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                         <g clip-path="url(#clip0_304_19492)">
                            <path d="M8.00004 10.6668V8.00016M8.00004 5.3335H8.00671M14.6667 8.00016C14.6667 11.6821 11.6819 14.6668 8.00004 14.6668C4.31814 14.6668 1.33337 11.6821 1.33337 8.00016C1.33337 4.31826 4.31814 1.3335 8.00004 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                         </g>
                         <defs>
                            <clipPath id="clip0_304_19492">
                               <rect width="16" height="16" fill="white"/>
                            </clipPath>
                         </defs>
                      </svg>
                   </span>
                </div> */}
                <div class="uAI" style={{width:"100%"}}>
                   <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                         <g clip-path="url(#clip0_479_1777)">
                            <path d="M5.41663 10.8332L6.07034 12.1406C6.29158 12.5831 6.4022 12.8043 6.54999 12.996C6.68112 13.1662 6.83363 13.3187 7.00375 13.4498C7.19547 13.5976 7.41671 13.7082 7.8592 13.9295L9.16663 14.5832L7.8592 15.2369C7.41671 15.4581 7.19547 15.5687 7.00375 15.7165C6.83363 15.8477 6.68112 16.0002 6.54999 16.1703C6.4022 16.362 6.29158 16.5833 6.07034 17.0257L5.41663 18.3332L4.76291 17.0257C4.54167 16.5833 4.43105 16.362 4.28327 16.1703C4.15213 16.0002 3.99962 15.8477 3.8295 15.7165C3.63778 15.5687 3.41654 15.4581 2.97406 15.2369L1.66663 14.5832L2.97406 13.9295C3.41654 13.7082 3.63778 13.5976 3.8295 13.4498C3.99962 13.3187 4.15213 13.1662 4.28327 12.996C4.43105 12.8043 4.54167 12.5831 4.76291 12.1406L5.41663 10.8332Z" stroke="#9E77ED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.5 1.6665L13.4821 4.22019C13.7172 4.83121 13.8347 5.13672 14.0174 5.39371C14.1793 5.62147 14.3783 5.82046 14.6061 5.98241C14.8631 6.16514 15.1686 6.28264 15.7796 6.51765L18.3333 7.49984L15.7796 8.48202C15.1686 8.71703 14.8631 8.83454 14.6061 9.01727C14.3783 9.17922 14.1793 9.37821 14.0174 9.60597C13.8347 9.86295 13.7172 10.1685 13.4821 10.7795L12.5 13.3332L11.5178 10.7795C11.2828 10.1685 11.1653 9.86295 10.9825 9.60597C10.8206 9.37821 10.6216 9.17922 10.3938 9.01727C10.1368 8.83454 9.83133 8.71703 9.22031 8.48202L6.66663 7.49984L9.22031 6.51765C9.83133 6.28264 10.1368 6.16514 10.3938 5.98241C10.6216 5.82046 10.8206 5.62147 10.9825 5.39371C11.1653 5.13672 11.2828 4.83121 11.5178 4.22019L12.5 1.6665Z" stroke="#9E77ED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                         </g>
                         <defs>
                            <clipPath id="clip0_479_1777">
                               <rect width="20" height="20" fill="white"/>
                            </clipPath>
                         </defs>
                      </svg>
                   </span>
                   {/* {isSummarized === 0 ? (
                     <span onClick={generateAISummary}>
                        Summarize using AI
                     </span>
                     ) : (
                     <span style={{color:"gray"}}>
                        Summarized using AI
                     </span>
                     )} */}
    <span onClick={isSumLoading ? null : generateAISummary} style={{ cursor: isSummarized === 0 && !isSumLoading ? 'pointer' : 'default',color: isSummarized === 1 ? 'gray' : 'inherit' }}>
      {isSumLoading ? 'Loading...' : (isSummarized === 0 ? 'Summarize using AI' : 'Summarized using AI')}
    </span>
    <span onClick={isSumLoading ? null : handleCustomPrompt} style={{  marginLeft:"50px", cursor: isSummarized === 0 && !isSumLoading ? 'pointer' : 'default',color: isSummarized === 1 ? 'gray' : 'inherit' }}>
      {isSumLoading ? 'Loading...' : (isSummarized === 0 ? 'Custom Prompt' : '')}
    </span>
    
                </div>
             </div>
          </div>
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          {
            (showPlayButton) ? (
               
               <div style={{ display: 'flex', alignItems: 'center' }}>



                  
                  <button className="btn PrimaryOutline" style={{width:"100%" }} onClick={handlePlayClick} >
                     {isLoading ? 'Loading...' : 'Generate Audio'}
                  </button>

{/*AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA evem top div  <div style={{ display: 'flex', alignItems: 'center' }}>*/}


                  <div style={{ position: 'relative', width: '200px' }}  ref={dropdownRef}>
        <div
          className="btn PrimaryOutline" 
          onClick={handleDropdownToggle}
          style={{
            cursor: 'pointer',
            marginLeft: '10px',
            width: '100%', // Set width for the dropdown trigger
            textAlign: 'left', // Align text to the left
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '5px',
            lineHeight: '28px',
          }}
        >
          {selectedOption} ▼
        </div>
        {dropdownVisible && (
          <div
            style={{
               cursor:'pointer',
               position: 'absolute',
               top: '100%',
               width: '100%', // Set width for the dropdown container
               marginLeft: '10px',
               
               border: '1px solid #ccc',
               borderRadius: '4px',
               marginTop: '5px',
               textAlign: 'left', // Align text to the left
               left: '10', // Align the dropdown with the trigger button
               zIndex: '2', // Increase the z-index to ensure visibility
               maxHeight: '80px', // Set a fixed height for the dropdown list
               overflowY: 'auto',
            }}
          >
            {/* Customize your dropdown items */}
            <ul
              style={{
                listStyle: 'none',
                margin: 0,
                padding: 0,
              }}
            >
            
                {cloneVoices.map((voice, index) => (
    <li key={index} onClick={() => handleDropdownItemClick(voice.presenterId, voice.presenterName)}>
    {voice.presenterName && voice.presenterName.length > 6 ? `${voice.presenterName.substring(0, 6)}...` : voice.presenterName}
    </li>
  ))}
              {/* Add more options as needed */}
            </ul>
          </div>
        )}
      </div>

{/*AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA*/}


                 </div>
                  ) : ( 
          <div class="deckAudioPlayer">
                <div class="topHeader">
                <div class="iconText">
                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                     <path d="M18.3334 17.5V15.8333C18.3334 14.2801 17.2711 12.9751 15.8334 12.605M12.9167 2.7423C14.1383 3.23679 15.0001 4.43443 15.0001 5.83333C15.0001 7.23224 14.1383 8.42988 12.9167 8.92437M14.1667 17.5C14.1667 15.9469 14.1667 15.1703 13.913 14.5577C13.5747 13.741 12.9258 13.092 12.109 12.7537C11.4965 12.5 10.7199 12.5 9.16675 12.5H6.66675C5.11361 12.5 4.33704 12.5 3.72447 12.7537C2.90771 13.092 2.2588 13.741 1.92048 14.5577C1.66675 15.1703 1.66675 15.9469 1.66675 17.5M11.2501 5.83333C11.2501 7.67428 9.7577 9.16667 7.91675 9.16667C6.0758 9.16667 4.58341 7.67428 4.58341 5.83333C4.58341 3.99238 6.0758 2.5 7.91675 2.5C9.7577 2.5 11.2501 3.99238 11.2501 5.83333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                   <span>Preview</span>
                </div>
                <div class="userName">
                   <div class="avtName">
                      <span class="avtar">  {audioPresenterName?audioPresenterName.charAt(0).toUpperCase():""}</span>
                      <span class="txt">{audioPresenterName}</span>
                   </div>
                   <a href="javascript:void(0);">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                   </a>
                </div>
             </div>
               <div class="player">
   
               <audio
        ref={audioRef}
        controls
        src={audioUrl}
        {...(autoPlay && { autoPlay: true })}
        onPlay={() => setShowPlayButton(false)} // Hide button when audio starts playing
      >
        Your browser does not support the audio element.
      </audio>

               {/*audioUrl && <AudioPlayer audioUrl={audioUrl} />*/}
                 {/* { <StreamPlayer  />} */}
             </div>
          </div>
         
                  )}
         
<div>

      {/* {showPlayButton && (
        <button onClick={fetchAudioUrl}>Play Stream</button>
      )} */}
    </div>


       </div>
    </div>
 </div>
  );
};

export default DeckContentNavigationRight;

