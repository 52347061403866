import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';  // Import useHistory and useLocation
import { Helmet } from 'react-helmet';  
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Assets from './components/Assets'; 
import Modal from './components/Modal';
import CentreContent from './components/CentreContent';
import Login from './components/Login'; 
import Landing from './components/Landing'; 
import LoginAssets from './components/LoginAssets'; 
import DeckContentItemLayout from './components/DeckContentItemLayout'; // Import the detail component
import Presentation from './components/Presentation'; 
import AccountSetting from './components/AccountSetting'; 
import Analytics from './components/Analytics'; 
import { apiUrls } from "./utils/apiUrls";
import { callAPI } from "./utils/apiUtils";
import PaymentSetting from "./components/PaymentSetting";

const LoadingComponent = ({ isLoadingCo }) => (
  <div
    style={{
      opacity: isLoadingCo ? 0 : 1,
      transition: 'opacity 0.5s ease-in-out',
      height: !isLoadingCo ? "0px": "",
    }}
  >
    
  </div>
);

const App = () => {
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDeckContentDetail, setShowDeckContentDetail] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [activeNavItem, setActiveNavItem] = useState('Decks'); 
  const [fileUploadCompleted, setFileUploadCompleted] = useState(false);
  const [showPresentation, setshowPresentation] = useState(false);
  const [presentationData, setPresentationData] = useState(null);
  const [prevIndexNo, setPrevIndexNo] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [refreshEditContent, setRefreshEditContent] = useState(false);
  const [editSlideIndexNo, setEditSlideIndexNo] = useState(false);
  const [deckId, setDeckId] = useState(0);
  const [userDetail, setuserDetail] = useState('Author of Deck');
  const [analyticsItemId, setAnalyticsItemId] = useState(null);
  const [analyticsTitle, setAnalyticsTitle] = useState("Presentation");
  const [analyticsImage, setAnalyticsImage] = useState("");
  const [onCompleteAction, setOnCompleteAction] = useState(() => () => {});
  
  
  const [editTitle, setEditTitle] = useState('');
  const [editFileId, setEditFileId] = useState(null);
  const [editAction, setEditAction] = useState("default");
  const [refreshContent, setRefreshContent] = useState(false);
  const [isSharedPrsenation, setIsSharedPrsenation] = useState(false);
  
  const [isLoadingCo, setIsLoadingCo] = useState(true);
  
  

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data) before transitioning
    const fetchData = async () => {
      // Perform your asynchronous operations here
      await new Promise(resolve => setTimeout(resolve, 200)); // Simulating a delay

      // Once the asynchronous operation is complete, set isLoading to false
      setIsLoadingCo(false);
    };

    fetchData();
  }, []);

  const handleFileUploadComplete = () => {
    setFileUploadCompleted(true);
  };
  
  useEffect(() => {
    // Your logic for checking if the user is logged in
    // You might have a token saved in localStorage or make an API call
    // Update the isLoggedIn state accordingly
    const checkLoggedInStatus = () => {
      let userId=localStorage.getItem("userId")
      if(userId) {
        setIsLoggedIn(true);
      } else {
      setIsLoggedIn(false);
      }
      // Perform your check here and set isLoggedIn to true or false
      // Example: 
      // const token = localStorage.getItem('token');
      // setIsLoggedIn(!!token); // Set to true if token exists, false otherwise
    };

    checkLoggedInStatus();
  }, []);

const location = useLocation();
const navigate = useNavigate(); 
const queryParams = new URLSearchParams(location.search);
const reloadDeckContentItemId = queryParams.get('reloadDeckContentItem');

useEffect(() => {
  // setIsSharedPrsenation(true)
  // const reloadDeckContentItemId = queryParams.get('sharedDeck');

  const queryParams = new URLSearchParams(location.search);
  const deckIdParam = queryParams.get('deckId');
console.log(deckIdParam); 
console.log(location.pathname);
const prelogin = queryParams.get('prelogin');
  if ((location.pathname === '/sharedPresentation' || (location.pathname === '/sharedPresentation/') && deckIdParam ) && deckIdParam ) {
    import ('reveal.js/dist/reset.css');
import ('reveal.js/dist/reveal.css');
import ('reveal.js/dist/theme/black.css');
    setIsSharedPrsenation(true);
    onSharedPresentation(deckIdParam)
  } else if(prelogin === 'onlyforadmin' ){
    setShowLandingPage(false)
    navigate(location.pathname);
  } else {  
    setIsSharedPrsenation(false)
  const reloadDeckContentItemId = queryParams.get('reloadDeckContentItem');
  const prevIndexNo = queryParams.get('prevIndexNo');

  if (reloadDeckContentItemId) {
    const selectedTitle = queryParams.get('selectedTitle');
    const deckId =  queryParams.get('deckId')
    // Reset the query parameter to avoid re-triggering on subsequent renders
    setSelectedItemId(reloadDeckContentItemId);
    setPrevIndexNo(prevIndexNo)
    setShowDeckContentDetail(true);
    setSelectedTitle(selectedTitle)
    setDeckId(deckId)
    
    navigate(location.pathname);
  }
}
}, [location.search, location.pathname, navigate]);

  const handleLogout = () => {
    // Logic for handling logout
    setIsLoggedIn(false);
    localStorage.removeItem("userId");
    localStorage.removeItem("initial");
    // You might want to clear the token from localStorage or perform other cleanup
  };

  
  const handleLogin = () => {
    setIsLoggedIn(true);
  };
  
  const openModal = (editFileId=null,editTitle='',editAction='default', indexNo =0,itemId='' , onCompleteAction = '') => {
    console.log("fileIddddddddd",editFileId, editTitle, editAction, itemId, indexNo)
    setEditAction(editAction)
    setEditFileId(editFileId)
    setEditTitle(editTitle)
    setIsModalOpen(true);
    setEditSlideIndexNo(indexNo)
    setItemId(itemId)
    setOnCompleteAction(() => onCompleteAction)
     ///Need to handle omly im case of edit image
    // setShowDeckContentDetail(false);
   setRefreshEditContent(false); 
   setPrevIndexNo(indexNo)
   
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFileUploadCompleted(false); 
    setRefreshContent(!refreshContent);
    
  };

  const handleItemClick = (itemId,title, indexNo =0, deckId) => {
    console.log("Appjs item id", itemId, title)
    setSelectedItemId(itemId);
    setSelectedTitle(title);
    setRefreshEditContent(true); 
    setDeckId(deckId);
    setPrevIndexNo(indexNo)
    setShowDeckContentDetail(true);
  };

  const handleBackToDashboard = () => {
    console.log('jjjjjjjjjjjjjjjjjjjj');
    setShowDeckContentDetail(false);
    setSelectedItemId(null);
    setshowPresentation(false);
  };

  const handleItemAnly = (fileId, title, imageUrl) =>{
    setActiveNavItem("Analytics")
    setAnalyticsItemId(fileId)
    setAnalyticsTitle(title)
    setAnalyticsImage(imageUrl)
    
  }
const passToApp = (prevIndexNo) => {
  setPrevIndexNo(prevIndexNo)
}
  const onPresentationClick = async (data,itemId, prevIndexNo, title, deckId) => {
    import ('reveal.js/dist/reset.css');
    import ('reveal.js/dist/reveal.css');
    import ('reveal.js/dist/theme/black.css');
    const response = await callAPI(apiUrls.GETPPTDATA, { "fileId":itemId }, 'GET');
    
  // setItemDetail(response.data);
  // setPresentationData(data);
  setPrevIndexNo(prevIndexNo)
  setPresentationData(response.data);
  setDeckId(deckId)
  console.log("response.data",selectedTitle, title)
 
    setshowPresentation(true);
  }

  const [helmetElement, setHelmetElement] = useState(
    <Helmet>
      <title>Default Page Title</title>
      <meta name="description" content="Default page description" />
      <meta property="og:image" content={"https://cdn.deck.work/siteimages/313882652-ed03e9fd-f71a-4092-8b60-3a42bb5e47f5.jpg"} />
      <meta property="og:image:width" content="1500" />
      <meta property="og:image:height" content="944" />
      <meta property="og:image:type" content={"image/jpeg"} />
      <meta itemprop="image" content={"https://cdn.deck.work/siteimages/313882652-ed03e9fd-f71a-4092-8b60-3a42bb5e47f5.jpg"}/>
      <meta name="twitter:image" content={"https://cdn.deck.work/siteimages/313882652-ed03e9fd-f71a-4092-8b60-3a42bb5e47f5.jpg"}/>
      
    </Helmet>
  );


  
  const onSharedPresentation = async (itemId) => {
    const response = await callAPI(apiUrls.GETPPTDATABYDECKID, { "deckId":itemId }, 'GET');
    if(response.data.processedResults) {
      setuserDetail(response.data.userDetail)
      console.log(response.data.processedResults[0].value)
      setSelectedItemId(response.data.userDetail.fileId)
      setPresentationData(response.data.processedResults);
      const newHelmetElement = (
        <Helmet>
          {response.data.userDetail.title && <title>{response.data.userDetail.title}</title>}
          {response.data.userDetail.title && <meta name="description" content={response.data.userDetail.title} />}
        </Helmet>
      );
      setHelmetElement(newHelmetElement);
    } else {
      setIsSharedPrsenation(false);
    }
  }

  



  return (

    <>
    {isLoadingCo ? (
  <LoadingComponent isLoadingCo={isLoadingCo}/>
) : (
  <>
    {helmetElement}
    {isSharedPrsenation ? (
<Presentation
  PresentationData={presentationData}
  onBack={handleBackToDashboard}
  itemId={selectedItemId}
  prevIndexNo={prevIndexNo}
  isSharedPrsenation={isSharedPrsenation}
  userDetail={userDetail}
  deckId={deckId}
/>
) : (
<>
{!isLoggedIn ? (
         showLandingPage ? (
          <>
          <LoginAssets />
          <Landing handleLogin={handleLogin} />
          </>
        ) : (
        <>
          <LoginAssets />
          <Login handleLogin={handleLogin} />
        </>
         )
      ) : (
        showPresentation ? (
          <Presentation PresentationData={presentationData} onBack={handleBackToDashboard} itemId={selectedItemId} prevIndexNo={prevIndexNo} isSharedPrsenation={isSharedPrsenation} selectedTitle={selectedTitle} userDetail={userDetail} deckId={deckId}/>
        ) : (
        <div>
          <Assets />
          {isModalOpen && <Modal closeModal={handleCloseModal } activeNavItem={activeNavItem} onFileUploadComplete={handleFileUploadComplete} onItemClick={handleItemClick} editFileId={editFileId} editTitle={editTitle} editAction={editAction} setActiveNavItem={setActiveNavItem} itemId={itemId} editSlideIndexNo={editSlideIndexNo} userDetail={{userDetail}} onCompleteAction={onCompleteAction}/>}
          {showDeckContentDetail ? (
            <DeckContentItemLayout
              itemId={selectedItemId}
              title={selectedTitle}
              onBack={handleBackToDashboard}
              onPresentationClick={onPresentationClick}
              prevIndexNo={prevIndexNo}
              openModal={openModal}
              refreshEditContent={refreshEditContent}
              deckId={deckId}
              passToApp={passToApp}
              

            />
          ) : (
            <>
            <div className="container">
              <Header handleLogout={handleLogout} setActiveNavItem={setActiveNavItem}/>
              <div class="deckDashboard">
                <Navigation activeNavItem={activeNavItem} setActiveNavItem={setActiveNavItem} openModal={openModal} />
                
                {activeNavItem === 'Account' ? (
                  <AccountSetting />
                )  : activeNavItem === 'Payment' ? (
                  <PaymentSetting />
                 ) : activeNavItem === 'Analytics' ? (
                   <Analytics analyticsItemId={analyticsItemId} analyticsTitle={analyticsTitle} analyticsImage={analyticsImage} setActiveNavItem={setActiveNavItem}/>
                  ) : (  
                  <CentreContent onItemClick={handleItemClick} openModal={openModal}  activeNavItem={activeNavItem} fileUploadCompleted={fileUploadCompleted}   refreshContent={refreshContent} onItemAnaly={handleItemAnly} prevIndexNo={prevIndexNo}/>
                )}
              </div>
              </div>  
              <Footer />
            </>
          )}
        </div>
        )
      )}
      </>
      )}
      </>
)}
    </>
  );
};

export default App;
