import React from 'react';

const SuccessMessage = ({ closeModal, activeNavItem, onItemClick, uploadedFileId, uploadedTitle,setActiveNavItem, editAction, editSlideeIndexNo, isSuccess}) => {
  // You can include any logic or UI elements specific to the success message here
  const handleOpenClick = () => {
  if (activeNavItem === "Presenters") {
    setActiveNavItem("Decks")
    closeModal()
  } else {
    console.log("SuccessOpen", uploadedFileId,uploadedTitle)
    onItemClick(uploadedFileId, uploadedTitle, editSlideeIndexNo); 
    closeModal()
  }
   
  };

  
  const handleConfirm = () => {
    // You can add any specific actions when confirming the success message
    closeModal(); // For example, close the modal using the closeModal function passed as a prop
  };
  let label1 = '';
  let label2 = '';
  let svglabel
  if(isSuccess === true) {
    svglabel = ''
  } else {}
  if(editAction === 'imageUpload') {
    
    label1 = isSuccess?"Your Media File is uploaded":uploadedTitle//"Your Media FIle is uploaded"
    label2 =  "Continue Edit"
 } else {  
   label1 = activeNavItem === "Presenters"?`Your voice ${uploadedTitle} is ready for use`:"Your deck was imported successfully"
   label2 = activeNavItem === "Presenters"?"Go to Decks":"Open Deck"
 }
  return (
    <div className="success-message">
      <div class="model_box_child deckCreatedPop" id="deckCreatedPop" style={{width: '752px', display: 'block!important'}}>
         <div class="contentSection">
            <div class="loaderIcon">
            {isSuccess === true ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">    
                  <path d="M25.0001 40.5L35.0001 50.5L55.0001 30.5M73.3334 40.5C73.3334 58.9095 58.4096 73.8333 40.0001 73.8333C21.5906 73.8333 6.66675 58.9095 6.66675 40.5C6.66675 22.0905 21.5906 7.16666 40.0001 7.16666C58.4096 7.16666 73.3334 22.0905 73.3334 40.5Z" stroke="#067647" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> 
              </svg>
            ):(
              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">  
              <path d="M10 10 L70 70 M10 70 L70 10" stroke="red" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            ) } 
            </div>
            <p class="mt-24">{label1}</p>
            <a href="javascript:void(0);" class="btn InversePrimary mt-24"  onClick={handleOpenClick}>{label2}</a>
            {editAction !== 'imageUpload' && (
            <a href="javascript:void(0);" class="btn Secondary mt-16" onClick={handleConfirm}>Close This Window</a>
            )}
         </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
