import React, { useState } from 'react';
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from "@react-oauth/google";

import  CustomGoogleButton  from '../components/CustomGoogleButton';

const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLoginClick = async (e) => {
    console.log("hhhhh")
    e.preventDefault();
    try { 
    if (!username || !password) {
      setErrorMessage('Please enter both username and password.');
    } else {
        setErrorMessage('');
      const formData = new FormData();
      //formdata.append("file", files[0].file);
      formData.append("email", username);
      formData.append("password", password);
      
      console.log("Username:", username);
      console.log("Password:", password);

      const headers={
        "Content-Type": "application/json",
        }
      const response = await callAPI(apiUrls.LOGIN, {}, "POST",formData,{},headers);

      // Here, you might perform validation before calling handleLogin
      // For simplicity, let's assume the login is successful immediately
      console.log(response);
      if(response.data.userId){
        console.log("userId",response.data.userId)
        localStorage.setItem("userId",response.data.userId)

        const initial = (response?.data?.name?.charAt(0) ?? response?.data?.email?.charAt(0) ?? 'U').toUpperCase();
        localStorage.setItem("initial",initial)
      handleLogin(); // Call handleLogin function passed from parent component
      console.log("bbbb")
    } else {
      setErrorMessage('Invalid username or password.');
    }
    
    
  } 
} catch (error) {
        console.error('An error occurred:', error);
  }

  };

  return (
    <div class="container">
   <div class="wrapper flex alignCenter">
      <div class="loginSection" style={{ minWidth: '380px'}}>
         <div class="headDeck">
            <div class="logo">
               <div class="logoImg">
                  <svg xmlns="http://www.w3.org/2000/svg" width="158" height="53" viewBox="0 0 158 53" fill="none">
                    <path d="M23.1627 51.668H0.5V0H23.1627C26.4223 0.0467372 29.1347 0.420635 31.2998 1.12169C34.9877 2.31349 37.9737 4.49846 40.2579 7.67659C42.0899 10.2471 43.339 13.028 44.0052 16.0192C44.6714 19.0104 45.0045 21.8613 45.0045 24.5721C45.0045 31.4425 43.6007 37.2612 40.7932 42.0284C36.9863 48.4548 31.1095 51.668 23.1627 51.668ZM31.3355 13.1799C29.6462 10.3757 26.3033 8.97355 21.3068 8.97355H11.1711V42.6944H21.3068C26.4937 42.6944 30.1102 40.1823 32.1564 35.1581C33.2747 32.4006 33.8338 29.1173 33.8338 25.3082C33.8338 20.0503 33.001 16.0075 31.3355 13.1799Z" fill="#D9D9D9"/>
                    <path d="M81.7773 40.4511C81.5156 42.7178 80.3141 45.0196 78.1727 47.3565C74.8417 51.0721 70.1783 52.9299 64.1825 52.9299C59.2336 52.9299 54.8676 51.3642 51.0846 48.2328C47.3015 45.1014 45.41 40.0071 45.41 32.9497C45.41 26.3364 47.1111 21.2654 50.5135 17.7368C53.9397 14.2081 58.3771 12.4438 63.8256 12.4438C67.0615 12.4438 69.9761 13.0397 72.5695 14.2315C75.1629 15.4233 77.3043 17.3045 78.9936 19.875C80.5163 22.1418 81.5037 24.7707 81.9558 27.7619C82.2175 29.5145 82.3246 32.0384 82.277 35.3333H55.6528C55.7955 39.1658 57.0209 41.8532 59.3288 43.3955C60.7326 44.3536 62.4218 44.8327 64.3967 44.8327C66.4904 44.8327 68.1916 44.2485 69.5002 43.08C70.214 42.4491 70.8445 41.5728 71.3918 40.4511H81.7773ZM71.713 28.7434C71.5464 26.1027 70.7256 24.1047 69.2504 22.7493C67.799 21.3706 65.9908 20.6812 63.8256 20.6812C61.4701 20.6812 59.6381 21.4056 58.3295 22.8545C57.0447 24.3034 56.2357 26.2663 55.9026 28.7434H71.713Z" fill="#D9D9D9"/>
                    <path d="M118.8 27.166H108.379C108.188 25.7405 107.701 24.4552 106.915 23.3102C105.773 21.7679 104.001 20.9967 101.598 20.9967C98.1716 20.9967 95.828 22.6675 94.5669 26.0093C93.9007 27.7853 93.5676 30.1455 93.5676 33.0899C93.5676 35.8942 93.9007 38.1493 94.5669 39.8552C95.7804 43.0333 98.0645 44.6224 101.419 44.6224C103.799 44.6224 105.488 43.9914 106.487 42.7295C107.486 41.4676 108.093 39.8318 108.307 37.8221H118.693C118.455 40.86 117.337 43.7343 115.338 46.4451C112.15 50.815 107.427 53 101.169 53C94.9119 53 90.308 51.1772 87.3577 47.5317C84.4074 43.8862 82.9322 39.1541 82.9322 33.3353C82.9322 26.7687 84.562 21.6627 87.8217 18.0172C91.0813 14.3717 95.5781 12.5489 101.312 12.5489C106.19 12.5489 110.175 13.6239 113.268 15.7738C116.385 17.9237 118.229 21.7211 118.8 27.166Z" fill="#D9D9D9"/>
                    <path d="M157.5 51.668H145.223L135.908 35.3333L131.697 39.6448V51.668H121.704V0.175265H131.697V28.0073L144.366 13.6356H156.965L143.367 28.2526L157.5 51.668Z" fill="#D9D9D9"/>
                  </svg>
               </div>
               <div class="betaTag">
                  BETA
               </div>
            </div>
         </div>
         <div class="headTxt mt-24">
            <h3>Sign in</h3>
            <p class="mt-8">You’re here because you requested<br/>access to Deck.</p>
         </div>
         <form onSubmit={handleLoginClick} className="emailPasswordForm">
         {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
         <div class="dForm dbBox mt-12 gLoginForm">
            <div class="row gap24 flexColum w100">
               <div class="col flex flexColum w100">
                  <label class="mb-8">User name</label>
                  <input type="text" name="" placeholder="username" class="w100"             id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}/>
               </div>
               <div class="col flex flexColum w100">
                  <label class="mb-8">Password</label>
                  <input type="password" name="" placeholder="password" class="w100" id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required/>
                 
               </div>
            </div>
             <div class="actionButtons jcflxStart w100">
               <button class="btn PrimaryOutline" type="submit" >Log In</button>
             </div>
          </div>
          </form>
          
          <GoogleOAuthProvider clientId="1039487477793-8iv47jsvilbe8ug57q9503o79u55a4pv.apps.googleusercontent.com"  scope="https://www.googleapis.com/auth/presentations">
              <CustomGoogleButton handleLogin={handleLogin} />
            </GoogleOAuthProvider>

      </div>
   </div>
</div>
  );
};

export default Login;
