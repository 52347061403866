import React, { useState, useEffect } from "react";
import DeckContentItem from './DeckContentItem'; 
import PresenterContentItem from './PresenterContentItem'; 
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";


const CentreContent = ({ onItemClick, openModal, activeNavItem, fileUploadCompleted, refreshContent, onItemAnaly, prevIndexNo }) => {
   const [contentItems, setContentItems] = useState([]);
   const [presenterItems, setPresenterItems] = useState([]);
  // const [activeNavItem, setActiveNavItem] = useState('Decks'); 


   useEffect(() => {
      console.log("activeNavItem",activeNavItem)
     // Fetch data from your API endpoint
  //   var arr = [1,2,3,4,5,6];
   //  setContentItems(arr)
     const fetchData = async () => {
       try {
         const userId=localStorage.getItem("userId")
    
         console.log("activeNavItem",activeNavItem)
         
         if (activeNavItem === 'Decks') {
            const response = await callAPI(apiUrls.GETFILE, {userId:userId}, "GET");;
            console.log(response);
            setContentItems(response.data)
          } else if (activeNavItem === 'Presenters') {
            const presenterResponse = await callAPI(apiUrls.GETPRESENTERS, { userId: userId }, "GET");
            console.log(presenterResponse);
            setPresenterItems(presenterResponse.data);
          }
    
       } catch (error) {
         console.error('Error fetching data:', error);
       }
     };
 
     fetchData(); // Call the fetchData function when the component mounts
   }, [activeNavItem,fileUploadCompleted, refreshContent]); // The empty dependency array [] ensures this runs once on mount
 
   const handleImageClick = (fileId, title, indexNo = 0, deckId) => {
      // Implement logic to show detail based on fileId, for example:
      console.log('Clicked on image with fileId:', fileId);
      // You can call a function to open details here
      // For example: openDetailsModal(fileId);
      onItemClick(fileId, title, indexNo,  deckId); 
    };

    const [visibleUl, setVisibleUl] = useState(null);
    const handleToggleUl = (fileId) => {
      setVisibleUl((prevVisibleUl) => (prevVisibleUl === fileId ? null : fileId));
    };
    const [isVisible, setIsVisible] = useState(true);
    const handleCloseSave = () => {
      localStorage.setItem('saveClose', 1);
      setIsVisible(false);
    };
    const localStorageValue = localStorage.getItem('saveClose');
  return (
    <div class="dashContent">
       {isVisible &&!localStorageValue && (
         <div class="boxGT800 welcomeDeckInfo mb-32">
            {/* <div class="bIcon">
               <img src="../assets/images/Featured icon outline.png" alt="" />
            </div> */}
            <div class="video mt-12">
               <iframe src="https://player.vimeo.com/video/936999108?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"  title="Deck_How To"></iframe>
               <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
            <div class="w100" style={{margin:"10px"}}>
               <h4 class="jcSpacebetween flex">
                  <span>Welcome to Deck!</span>
                  <svg  onClick={handleCloseSave} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="cPointer">
                    <path d="M15 5L5 15M5 5L15 15" stroke="#A3A3A3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
               </h4>
               <p class="mt-4">We’re glad to have you here and test it out. To get started, have a look at the video below:</p>
             
            </div>
         </div>
       )}   
         <h1>
            <span>{activeNavItem}</span>
            <a class="btn Primary open_model" data-target="importingPPPop" onClick={openModal} href="javascript:void(0);">Add new</a>
         </h1>
       
         {activeNavItem  === 'Presenters' ? (
                     <div class="presListing mt-24 presListing rowList flexWrap">
           
{presenterItems.map((item, index) => (
<PresenterContentItem 
key={item.presenterId}
 title={item.presenterName}
 presenterId={item.presenterId}
 isVisible={visibleUl === item.presenterId}
 onToggle={handleToggleUl}
 openModal={openModal} 
/>
    ))}
            
            </div>
) : (
   <div class="deckListing mt-24">
         <div class="rowList flexWrap">
        
            {contentItems.map((item, index) => (

               <DeckContentItem
               key={item.fileId}
               fileId={item.fileId}
               title={item.titleName}
               imageUrl={item.images.imageUrl}
               onImageClick={handleImageClick} // Pass the handleImageClick function
               isVisible={visibleUl === item.fileId}
               onToggle={handleToggleUl}
               openModal={openModal} 
               deckId={item.deckId}
               onItemAnaly={onItemAnaly}
               prevIndexNo={prevIndexNo}
               />
               
                 ))}

            </div>
        
         </div>   
)}
      
      </div>
      );
    };
    
    export default CentreContent;