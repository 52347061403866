import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../components/Modal';

const DeckContentHeader = ({ title, onBack, onDeckHeaderItemClick, deckId, itemId , onCompleteAction}) => {
  const { fileId } = useParams();
  const [initial, setInitial] = useState("U");
  const [copyDeckId, setCopyDeckId] = useState("");
//console.log("Deadlyyyyy")
  // Fetch data using fileId and render content
  // ...
  const [showSync, setShowSync] = useState(false);

  const handleSyncClick = (action) => {
    // Call the callback to open the modal in App.js
    setShowSync(true)
  };

  const   handleCompleteAction = () => {
    // Call the callback to open the modal in App.js
    onCompleteAction()
  };



  const handleCloseModal = () => {
    setShowSync(false)
  };

  const handleItemClick = (itemData) => {
    // Access deckData here or perform actions related to the clicked item
    onDeckHeaderItemClick(itemData); // Pass the clicked item's data to App.js
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  useEffect(() => {
  //  deckId = "xcvdd"
  if(deckId !== undefined && deckId !== null) {
    setCopyDeckId(deckId)
  }
    
    console.log("ittttttttttttttt".itemId)
    let initial=localStorage.getItem("initial")
    setInitial(initial)

  }, []);

  const copyToClipboard = (path) => {
    const baseUrl = window.location.origin; // or window.location.protocol + '//' + window.location.host;
    const fullUrl = `${baseUrl}${path}`;
  
    navigator.clipboard.writeText(fullUrl)
      .then(() => {
        console.log('Link copied to clipboard:', fullUrl);
        const linkElement = document.querySelector('.open_modelgry'); // Get the <a> element by its class
        if (linkElement) {
          // Change text to "Link Copied"
          linkElement.textContent = 'Link Copied';
          // Revert back after 2 seconds
          setTimeout(() => {
            linkElement.textContent = 'Copy Link';
          }, 2000);
        }
      })
      .catch((err) => {
        console.error('Error copying link to clipboard:', err);
        // Handle the error or provide user feedback
      });
}

  return (
    <>
    {showSync && <Modal closeModal={handleCloseModal } editAction={"sync"} itemId={itemId} editTitle={title} onCompleteAction={onCompleteAction}/>}
    <div class="headerWrap">
    <div class="header">
        <div class="deckTitle">
            <h6>
                <a href="javascript:void(0);" onClick={onBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M15 18L9 12L15 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <span>{title}</span>
                <a href="javascript:void(0);">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </h6>
        </div>
        <div class="rSec">
            <span class="customIconsLinks">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_465_1725)">
                    <path d="M3.75008 18.3332V14.1665M3.75008 5.83317V1.6665M1.66675 3.74984H5.83341M1.66675 16.2498H5.83341M10.8334 2.49984L9.38827 6.25722C9.15326 6.86825 9.03575 7.17376 8.85302 7.43074C8.69107 7.6585 8.49208 7.8575 8.26432 8.01945C8.00734 8.20217 7.70182 8.31968 7.0908 8.55469L3.33342 9.99984L7.0908 11.445C7.70183 11.68 8.00734 11.7975 8.26432 11.9802C8.49208 12.1422 8.69107 12.3412 8.85302 12.5689C9.03575 12.8259 9.15326 13.1314 9.38827 13.7425L10.8334 17.4998L12.2786 13.7425C12.5136 13.1314 12.6311 12.8259 12.8138 12.5689C12.9758 12.3412 13.1748 12.1422 13.4025 11.9802C13.6595 11.7975 13.965 11.68 14.576 11.445L18.3334 9.99984L14.576 8.55469C13.965 8.31968 13.6595 8.20217 13.4025 8.01945C13.1748 7.8575 12.9758 7.6585 12.8138 7.43074C12.6311 7.17376 12.5136 6.86825 12.2786 6.25722L10.8334 2.49984Z" stroke="url(#paint0_linear_465_1725)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <linearGradient id="paint0_linear_465_1725" x1="1.66675" y1="1.6665" x2="18.3334" y2="1.6665" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#7F56D9"/>
                      <stop offset="1" stop-color="#9E77ED"/>
                    </linearGradient>
                    <clipPath id="clip0_465_1725">
                      <rect width="20" height="20" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
            </span>
            <a href="javascript:void(0);" class="btn PrimaryOutline"  onClick={() => handleSyncClick()}>Sync</a>
            <a href="javascript:void(0);" class="btn PrimaryOutline"  onClick={() => handleItemClick()}>Present</a>
            <a href="javascript:void(0);" class="btn Primary open_modelgry"  onClick={() => copyToClipboard(`/sharedPresentation?deckId=${copyDeckId}`)} data-target="deckSharePop">Copy Link</a>
            <div class="userNav">
                <i class="userAvtar">{initial}</i>
                <ul class="dropMenu">
                    <li>
                        <a href="javascript:void(0);">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                              <path d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z" stroke="#A3A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>Account Settings</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="active open_modelgry" data-target="planPackagesPop">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <g clip-path="url(#clip0_442_1752)">
                                <path d="M10.0003 9.99995V5.99995M10.0003 5.99995H6.00029M10.0003 5.99995L6.00029 9.99988M14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992Z" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_442_1752">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                            <span>Upgrade Plan</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6M8 11.3333C8 11.9533 8 12.2633 7.93185 12.5176C7.74692 13.2078 7.20782 13.7469 6.51764 13.9319C6.26331 14 5.95332 14 5.33333 14H5C4.06812 14 3.60218 14 3.23463 13.8478C2.74458 13.6448 2.35523 13.2554 2.15224 12.7654C2 12.3978 2 11.9319 2 11V5C2 4.06812 2 3.60218 2.15224 3.23463C2.35523 2.74458 2.74458 2.35523 3.23463 2.15224C3.60218 2 4.06812 2 5 2H5.33333C5.95332 2 6.26331 2 6.51764 2.06815C7.20782 2.25308 7.74692 2.79218 7.93185 3.48236C8 3.7367 8 4.04669 8 4.66667" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>Sign out</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</>
  );
};

export default DeckContentHeader;

