import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

//const TRACKING_API_URL = 'https://your-tracking-api.com/track'; // Replace with your actual API URL
const addTrackData = (trackData) => {
    //const queryParams = new URLSearchParams(trackData).toString();
    //const url = `${TRACKING_API_URL}?${queryParams}`;
console.log("trackdata", trackData)
if (trackData.en && trackData.en.trim() !== '') {
    const response = callAPI(apiUrls.TRACKDATA, trackData, 'GET');
}   
};

export default addTrackData;
