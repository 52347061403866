import React, { useState, useRef , useEffect}  from 'react';
import { Link } from 'react-router-dom';

const EditSlide = ({ openModal, slideId, indexNo, title, itemId }) => {
    const handleImageClick = (action) => {
        // Call the callback to open the modal in App.js
        console.log("Innnnn", itemId)
        openModal(slideId,title, action, indexNo, itemId );
      };
  return (

   <div class="prePreviewNavIcons">
   <div class="sControlLinks">
      {/* <a href="javascript:void(0);">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M4.5 9.50033H15.75C17.8211 9.50033 19.5 11.1793 19.5 13.2503C19.5 15.3214 17.8211 17.0003 15.75 17.0003H12M4.5 9.50033L7.83333 6.16699M4.5 9.50033L7.83333 12.8337" stroke="#D9D9D9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
      </a>
      <a href="javascript:void(0);">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M19.5 9.50033H8.25C6.17893 9.50033 4.5 11.1793 4.5 13.2503C4.5 15.3214 6.17893 17.0003 8.25 17.0003H12M19.5 9.50033L16.1667 6.16699M19.5 9.50033L16.1667 12.8337" stroke="#D9D9D9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
      </a> */}
   </div>
   <div class="sControlActionLinks">
      {/* <a href="javascript:void(0);">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M8.66667 7.83333H15.3333M12 7.83333V16.1667M8.5 19.5H15.5C16.9001 19.5 17.6002 19.5 18.135 19.2275C18.6054 18.9878 18.9878 18.6054 19.2275 18.135C19.5 17.6002 19.5 16.9001 19.5 15.5V8.5C19.5 7.09987 19.5 6.3998 19.2275 5.86502C18.9878 5.39462 18.6054 5.01217 18.135 4.77248C17.6002 4.5 16.9001 4.5 15.5 4.5H8.5C7.09987 4.5 6.3998 4.5 5.86502 4.77248C5.39462 5.01217 5.01217 5.39462 4.77248 5.86502C4.5 6.3998 4.5 7.09987 4.5 8.5V15.5C4.5 16.9001 4.5 17.6002 4.77248 18.135C5.01217 18.6054 5.39462 18.9878 5.86502 19.2275C6.3998 19.5 7.09987 19.5 8.5 19.5Z" stroke="#D9D9D9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>

      </a> */}
      <a href="javascript:void(0);" onClick={() => handleImageClick("imageUpload")}>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 19.5H7.77614C7.2713 19.5 7.01887 19.5 6.90199 19.4002C6.80056 19.3135 6.74674 19.1836 6.75721 19.0506C6.76927 18.8974 6.94776 18.7189 7.30474 18.3619L14.3905 11.2761C14.7205 10.9461 14.8855 10.7811 15.0758 10.7193C15.2432 10.6649 15.4235 10.6649 15.5908 10.7193C15.7811 10.7811 15.9461 10.9461 16.2761 11.2761L19.5 14.5V15.5M15.5 19.5C16.9001 19.5 17.6002 19.5 18.135 19.2275C18.6054 18.9878 18.9878 18.6054 19.2275 18.135C19.5 17.6002 19.5 16.9001 19.5 15.5M15.5 19.5H8.5C7.09987 19.5 6.3998 19.5 5.86502 19.2275C5.39462 18.9878 5.01217 18.6054 4.77248 18.135C4.5 17.6002 4.5 16.9001 4.5 15.5V8.5C4.5 7.09987 4.5 6.3998 4.77248 5.86502C5.01217 5.39462 5.39462 5.01217 5.86502 4.77248C6.3998 4.5 7.09987 4.5 8.5 4.5H15.5C16.9001 4.5 17.6002 4.5 18.135 4.77248C18.6054 5.01217 18.9878 5.39462 19.2275 5.86502C19.5 6.3998 19.5 7.09987 19.5 8.5V15.5M10.75 9.08333C10.75 10.0038 10.0038 10.75 9.08333 10.75C8.16286 10.75 7.41667 10.0038 7.41667 9.08333C7.41667 8.16286 8.16286 7.41667 9.08333 7.41667C10.0038 7.41667 10.75 8.16286 10.75 9.08333Z" stroke="#D9D9D9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </a>
      {/* <a href="javascript:void(0);">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50002 4.5L8.66669 8.66667M15.3334 4.5L14.5 8.66667M20.3334 8.66667H3.66669M7.66669 19.5H16.3334C17.7335 19.5 18.4336 19.5 18.9683 19.2275C19.4387 18.9878 19.8212 18.6054 20.0609 18.135C20.3334 17.6002 20.3334 16.9001 20.3334 15.5V8.5C20.3334 7.09987 20.3334 6.3998 20.0609 5.86502C19.8212 5.39462 19.4387 5.01217 18.9683 4.77248C18.4336 4.5 17.7335 4.5 16.3334 4.5H7.66669C6.26656 4.5 5.56649 4.5 5.03171 4.77248C4.56131 5.01217 4.17885 5.39462 3.93917 5.86502C3.66669 6.3998 3.66669 7.09987 3.66669 8.5V15.5C3.66669 16.9001 3.66669 17.6002 3.93917 18.135C4.17885 18.6054 4.56131 18.9878 5.03171 19.2275C5.56649 19.5 6.26656 19.5 7.66669 19.5Z" stroke="#D9D9D9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </a> */}
   </div>
   <div class="sControlActionLinksText">
      {/* <a href="javascript:void(0);">
        <span>Background</span>
      </a>
      <a href="javascript:void(0);">
         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033" stroke="#D9D9D9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>

           <span>Remove slide</span>
      </a> */}
   </div>
</div>
    

 
  );
};

export default EditSlide;
